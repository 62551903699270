<template>
<div id="page" class="order-distribution-page-index">
  <div class="odpi-top">
    <div class="odpit-title-line row-center-start">
      <div class="odpittl-title row-center-start">
        <div class="big-title">订单数量统计</div>
        设备类型：
        <el-select v-model="form.deviceType">
          <el-option placeholder="请选择" v-for="(item, index) in deviceOption" :key="index" :label="item.txt_type_name" :value="item.txt_type_code" />
        </el-select>
      </div>
      <div class="odpittl-date-picker">
        查询日期：
        <el-date-picker style="width: 260px;" v-model="form.dateRange" value-format="YYYY-MM-DD" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
      </div>
      <el-button type="primary" @click="search()">搜索</el-button>
      <el-button type="primary" v-if="btnShow" @click="goBack()">返回</el-button>
    </div>
    <div class="odpit-pie-chart">
      <div v-if="pieShowNull" class="no-data column-center-center">暂无数据</div>
      <od-pie-chart ref="pieChart" v-else @showNull="(e) => {pieShowNull = e}" :param="form" @pieData="(e) => {receivePieData(e)}" @showBtn="(e)=>{btnShow=e}" />
    </div>
  </div>
  <div class="odpi-bottom">
    <template v-if="chartParam == null">
      <div class="no-chart row-start">
        <div class="big-title">增长趋势</div>
        <div class="no-data column-center-center">暂无数据</div>
      </div>
    </template>
    <template v-else>
      <od-cross-bar ref="crossBarChart" :paramData="chartParam" @transData="(e)=>{transData(e)}" />
      <od-bar-chart ref="loBarChart" :form="form" :paramData="barChart" />
    </template>
  </div>
</div>
</template>

<script>
// 统计分析 - 订单数量统计
import { toRefs, reactive, ref, onMounted, computed } from 'vue';
import odPieChart from './comps/odPieChart.vue';
import OdCrossBar from './comps/odCrossBar.vue';
import OdBarChart from './comps/odBarChart.vue';
import { getDeviceType } from "api/apis.js";
import { ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
// import { useStore } from 'vuex';
export default {
  components: { odPieChart, OdCrossBar, OdBarChart },
  setup() {
    const crossBarChart = ref(null);
    // const store = useStore();
    const loBarChart = ref(null);
    const pieChart = ref(null);
    const route = useRoute();
    let moment = require("moment");
    const state = reactive({
      form: {
        dateRange: [],
        deviceType: null,
        level: 1,
        // areas_name: computed(() => store.getters.areas_name),
        areas_name: computed(() => {
          if (process.env.NODE_ENV == "development") {
            return null;
          } else {
            let path = route.path,
            menuAuth = JSON.parse(localStorage.getItem("menuAuth")),
            index = menuAuth.findIndex(t => t.path == path);
            if (menuAuth[index].txt_area_codes == "0") {
              return null;
            } else {
              return menuAuth[index].txt_area_codes;
            }
          }
        }),
      },
      chartParam: null,
      barChart: null,
      btnShow: false,
      deviceOption: [],
      pieShowNull: false
    });

    // 搜索
    const search = () => {
      console.log(state.form)
      state.pieShowNull = false;
      state.form.level = 1;
      setTimeout(() => {
        if (loBarChart.value != null) {
          loBarChart.value.chartData = null;
        }
        state.chartParam = null;
        pieChart.value.level = 1;
        if (crossBarChart.value != null) {
          crossBarChart.value.level = 1;
        }
        pieChart.value.getData(state.form);
      }, 300)
    }

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          state.deviceOption = response.data;
          state.form.deviceType = state.deviceOption[0].txt_type_code;
          pieChart.value.getData(state.form)
        } else {
          state.deviceOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      })
    };

    // 接收 饼图传来的数据
    const receivePieData = (e) => {
      state.chartParam = e;
      state.form.level = pieChart.value.level;
      setTimeout(() => {
        crossBarChart.value.level = pieChart.value.level;
        crossBarChart.value.initChart(state.chartParam);
        loBarChart.value.chartData = null;
      }, 300);
    };

    // 接收 横向柱状图=》纵向柱状图 传递 数据
    const transData = (e) => {
      state.barChart = e;
      setTimeout(() => {
        loBarChart.value.getData(state.chartParam);
      }, 300);
    };

    // 返回事件
    const goBack = () => {
      let level = pieChart.value.level - 1,
        data = JSON.parse(localStorage.getItem("numOrderPie" + level));
      pieChart.value.level -= 1;
      data = { t_agent_id: data.pagent_id, deviceType: data.device_type, areas_name: data.areas_name, dateRange: [data.dt_from, data.dt_to], level: data.int_agent_leve };
      pieChart.value.getData(data);
      if (pieChart.value.level == 1) {
        state.chartParam = null;
      }
    };

    onMounted(() => {
      datePickerInit();
    })

    // 初始化 计算时间
    const datePickerInit = () => {
      // 今天
      let endDate = moment(new Date()).format('YYYY-MM-DD'),
        startDate = moment(new Date()).subtract(1, "months").format('YYYY-MM-DD');
      state.form.dateRange = [startDate, endDate];
      getDevices();
    };

    return {
      ...toRefs(state),
      datePickerInit,
      receivePieData,
      crossBarChart,
      loBarChart,
      transData,
      pieChart,
      goBack,
      getDevices,
      search
    }
  }
}
</script>

<style lang="scss" scoped>
.order-distribution-page-index {
  .odpi-top {
    height: 50%;
    border-bottom: 2px solid #e2dfdf;

    .odpit-title-line {
      margin-bottom: 6px;

      .big-title {
        font-weight: bold;
        font-size: 18px;
        margin-right: 10px;
      }

      .odpittl-title {
        margin-right: 10px;
      }

      .odpittl-date-picker {
        margin-right: 6px;
      }
    }

    .odpit-pie-chart {
      height: calc(100% - 35px);
    }
  }

  .odpi-bottom {
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .od-distribution-bar-cross-chart {
      width: 50%;
      height: 100%;
    }

    .od-distribution-bar-longitudinal-chart {
      width: 50%;
      height: 100%;
    }

    .no-chart {
      width: 100%;
      height: 100%;

      .big-title {
        font-weight: bold;
        font-size: 18px;
      }

      .no-data {
        width: 100%;
        height: calc(100% - 25px);
      }
    }
  }

  .no-data {
    width: 100%;
    height: 100%;
  }
}
</style>
