<template>
<div class="od-distribution-bar-cross-chart" v-loading="loading">
  <div class="odbcc-chart" :id="chartId"></div>
</div>
</template>

<script>
// 统计分析 - 订单数量统计 - 横向柱状图
import { useStore } from 'vuex';
import { onMounted, reactive, toRefs, computed, inject } from 'vue';
export default {
  props: {
    paramData: { type: Object },
  },
  setup(props, ctx) {
    const store = useStore();
    const echart = inject("echart");
    const state = reactive({
      chartId: computed(() => store.getters.UUID),
      chartData: null,
      loading: false,
      level: 1
    });

    onMounted(() => {
      initChart(null);
    });

    // 渲染图表
    const initChart = (data) => {
      state.chartData = data == null ? props.paramData : data;
      state.chartData.sort((a, b) => a.cnt_orders - b.cnt_orders);
      echart.init(document.getElementById(state.chartId)).dispose();
      let crBarChart = echart.init(document.getElementById(state.chartId));
      let titleText = "";
      if (state.level == 1) titleText = "省级合伙人";
      else if (state.level == 2) titleText = "市级合伙人";
      else if (state.level == 3) titleText = "市场专员";
      else titleText = "诊所";
      crBarChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        title: { text: "增长趋势 / " + titleText },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: { type: 'value', boundaryGap: [0, 0.01], axisLine: { show: true } },
        yAxis: { type: 'category', data: state.chartData.map(t => t.txt_partner_name) },
        series: [{
          name: '业绩情况',
          type: 'bar',
          barWidth: "20%",
          data: state.chartData.map(t => t.cnt_orders)
        }]
      })
      if (state.level <= 4) {
        crBarChart.on("click", function (params) {
          let result = state.chartData.filter(t => t.txt_partner_name == params.name);
          ctx.emit("transData", result[0]);
        })
      }
    };

    return {
      ...toRefs(state),
      initChart
    }
  }
}
</script>

<style lang="scss" scoped>
.od-distribution-bar-cross-chart {
  .odbcc-chart {
    height: 100%;
    width: 100%;
  }
}
</style>
