<template>
<div class="od-distribution-bar-longitudinal-chart" v-loading="loading">
  <div v-if="chartData!=null" class="odblc-chart" :id="chartId"></div>
  <div v-else class="no-data column-center-center">暂无数据</div>
</div>
</template>

<script>
// 统计分析 - 订单数量统计 - 纵向柱状图
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus';
import { getDeviceCrossChartData } from "api/apis.js";
import { computed, onMounted, reactive, toRefs, inject } from 'vue';
export default {
  props: {
    paramData: { type: Object, default: function () { return {} } },
    form: { type: Object, default: function () { return {} } },
  },
  setup(props) {
    const store = useStore();
    const echart = inject("echart");
    const state = reactive({
      chartData: null,
      chartId: computed(() => store.getters.UUID),
      loading: false,
      name: null
    });

    onMounted(() => {
      // getData();
    })

    const getData = () => {
      if (props.paramData) {
        state.loading = true;
        let param = {
          device_type: props.form.deviceType,
          agent_id: props.paramData.t_agent_id,
          dt_from: props.form.dateRange? props.form.dateRange[0]: null,
          dt_to: props.form.dateRange? props.form.dateRange[1]: null
        }
        // 去掉null值的键值对
        for (let i = 0; i < Object.keys(param).length; i++) {
          let key = Object.keys(param)[i];
          if (param[key] == null) {
            delete param[key];
            i--;
          }
        }
        getDeviceCrossChartData(param).then(response => {
          if (response.code == 200) {
            if ((response.data || []).length != 0) {
              state.chartData = response.data;
              console.log(state.chartData)
              setTimeout(() => {
                initChart();
              }, 300)
            } else {
              state.chartData = null;
            }
          } else {
            state.chartData = null;
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.loading = false;
        })
      } else {
        state.chartData = null;
      }
    };

    // 渲染图表
    const initChart = () => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let barChart = echart.init(document.getElementById(state.chartId));
      state.name = props.paramData.txt_partner_name;
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        dataZoom: [{
            type: 'slider',
            show: true,
            start: 0,
            end: 100,
            handleSize: 8
          },
          {
            type: 'inside',
            start: 0,
            end: 100
          }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: [0, 0.01],
          data: state.chartData.map(t => t.dt_order)
        },
        yAxis: { type: 'value', axisLine: { show: true } },
        series: [{
          name: state.name,
          type: 'bar',
          barWidth: "20%",
          data: state.chartData.map(t => t.cnt_orders)
        }]
      })
    };
    return {
      ...toRefs(state),
      initChart,
      getData
    }
  }
}
</script>

<style lang="scss" scoped>
.od-distribution-bar-longitudinal-chart {

  .odblc-chart,
  .no-data {
    height: 100%;
    width: 100%;
  }
}
</style>
